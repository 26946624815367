<template>
  <v-layout wrap v-if="editedItem" class="content-form">
    <v-toolbar
      class="title"
      elevation="0"
    >
    <v-icon large class="title-icon">{{ contentType.icon }}</v-icon>
      <v-toolbar-title>
        {{ $t(`common.${isClone ? 'clone' : isNew ? 'create' : 'edit'}`, locale) }} {{ contentType ? $t(`contentType.${contentType.name}`, locale).toLowerCase() : '' }}
      </v-toolbar-title>
    </v-toolbar>
    <v-flex xs12 class="alias">
      <v-text-field
        v-model="editedItem.Alias"
        :label="$t('content.alias', locale)"
        outlined
        hide-details
      />
    </v-flex>
    <v-flex xs12>
      <div v-if="[9].indexOf(editedItem.Type) >= 0" />
      <form-2-simple-text v-else-if="editedItem.Type === 2" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-3-space v-else-if="editedItem.Type === 3" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified"/>
      <form-4-image v-else-if="editedItem.Type === 4" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified"/>
      <form-5-iframe v-else-if="editedItem.Type === 5" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified"/>
      <form-6-alert v-else-if="editedItem.Type === 6" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-7-gallery v-else-if="editedItem.Type === 7" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-13-activities v-else-if="editedItem.Type === 13" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-16-text v-else-if="editedItem.Type === 16" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-17-contact v-else-if="editedItem.Type === 17" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-18-divider v-else-if="editedItem.Type === 18" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-19-show v-else-if="editedItem.Type === 19" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-20-site v-else-if="editedItem.Type === 20" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-27-food v-else-if="editedItem.Type === 27" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-28-link-domain v-else-if="editedItem.Type === 28" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-30-product-service v-else-if="editedItem.Type === 30" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-31-pdf-translate v-else-if="editedItem.Type === 31" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-32-link-external v-else-if="editedItem.Type === 32" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-33-channel-list v-else-if="editedItem.Type === 33" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-34-acttiv v-else-if="editedItem.Type === 34" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-35-poll v-else-if="editedItem.Type === 35" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-37-icon-list v-else-if="editedItem.Type === 37" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-43-icons v-else-if="editedItem.Type === 43" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-45-brochure v-else-if="editedItem.Type === 45" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified" :config="config" />
      <form-47-book-my-bookings v-else-if="editedItem.Type === 47" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified"/>
      <form-48-book-activities v-else-if="editedItem.Type === 48" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified"/>
      <form-49-menu-ext-food v-else-if="editedItem.Type === 49" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified"/>
      <form-50-attendance v-else-if="editedItem.Type === 50" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified"/>
      <form-51-weekly-menu v-else-if="editedItem.Type === 51" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified"/>
      <form-52-requests v-else-if="editedItem.Type === 52" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified"/>
      <form-53-issues v-else-if="editedItem.Type === 53" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified"/>
      <form-55-dviewcontent-messages v-else-if="editedItem.Type === 55" :editedItem="editedItem" :onChangeIsModified="handleChangeIsModified"/>
      <div v-else-if="[22].indexOf(editedItem.Type) >= 0" />
      <div v-else-if="isDevelopment"> {{ editedItem }}</div>
      
    </v-flex>
    <!--v-flex xs12 class="availability"-->
    <v-flex xs12 class="common-forms">
      <v-expansion-panels focusable>
        <insert-between-panel
          v-if="(isNew || isClone) && contentTypes"
          :locale="locale"
          :parentID="newParentID"
          :isMenu="false"
          :contentTypes="contentTypes"
          :onChange="handleChangeInsertBetween"
        />
        <content-padding 
          :locale="locale"
          v-model="editedItem.Padding"
          @change="handleChangeContentPadding"
        />
        <availability-panel
          :availability="editedItem.Availability"
          :locale="locale"
          :onChange="handleChangeAvailability"
        />
      </v-expansion-panels>
    </v-flex>
    <v-flex xs12 class="action-buttons">
      <action-buttons
        :id="editedItem.ID"
        :onDeleteItem="handleDelete"
        :onSaveItem="handleSave"
        :onClose="onClose"
        :locale="locale"
        :isClone="isClone"
      />
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import apiDishOrders from '@/services/apiDishOrders'
  import ActionButtons from '@/components/ActionButtons'
  import AvailabilityPanel from '@/components/AvailabilityPanel'
  import InsertBetweenPanel from '@/components/InsertBetweenPanel'
  import ContentPadding from './ContentPadding'
  import Form2SimpleText from './Form2SimpleText'
  import Form3Space from './Form3Space'
  import Form4Image from './Form4Image'
  import Form5Iframe from './Form5Iframe'
  import Form6Alert from './Form6Alert'
  import Form7Gallery from './Form7Gallery'
  import Form16Text from './Form16Text'
  import Form13Activities from './Form13Activities'
  import Form17Contact from './form17Contact/Index'
  import Form18Divider from './Form18Divider'
  import Form19Show from './Form19Show'
  import Form20Site from './Form20Site'
  import Form27Food from './Form27Food'
  import Form28LinkDomain from './Form28LinkDomain'
  import Form30ProductService from './Form30ProductService'
  import Form31PdfTranslate from './Form31PDFTranslate'
  import Form32LinkExternal from './Form32LinkExternal'
  import Form33ChannelList from './Form33ChannelList'
  import Form34Acttiv from './Form34Acttiv'
  import Form35Poll from './form35Poll/Index'
  import Form37IconList from './Form37IconList'
  import Form43Icons from './Form43Icons'
  import Form45Brochure from './Form45Brochure'
  import Form47BookMyBookings from './Form47BookMyBookings'
  import Form48BookActivities from './Form48BookActivities'
  import Form49MenuExtFood from './Form49MenuExtFood'
  import Form50Attendance from './Form50Attendance'
  import Form51WeeklyMenu from './Form51WeeklyMenu'
  import Form52Requests from './Form52Requests'
  import Form53Issues from './Form53Issues'
  import Form55DviewcontentMessages from './Form55DviewcontentMessages'
  export default {
    name: 'ContentForm',
    components: {
      ActionButtons,
      Form2SimpleText,
      Form3Space,
      Form4Image,
      Form5Iframe,
      Form6Alert,
      Form7Gallery,
      Form13Activities,
      Form16Text,
      Form17Contact,
      Form18Divider,
      Form19Show,
      Form20Site,
      Form27Food,
      Form28LinkDomain,
      Form30ProductService,
      Form31PdfTranslate,
      Form32LinkExternal,
      Form33ChannelList,
      Form34Acttiv,
      Form35Poll,
      Form37IconList,
      Form43Icons,
      Form45Brochure,
      Form47BookMyBookings,
      Form48BookActivities,
      Form49MenuExtFood,
      Form50Attendance,
      Form51WeeklyMenu,
      Form52Requests,
      Form53Issues,
      Form55DviewcontentMessages,
      ContentPadding,
      AvailabilityPanel,
      InsertBetweenPanel,
    },
    props: {
      editedID: {
        type: String,
        required: true
      },
      contentTypes: {
        type: Array,
        default: null,
      },
      newSelectedContentType: {
        type: Number,
        default: null,
      },
      newParentID: {
        type: String,
        default: null,
      },
      onClose: {
        type: Function,
        required: true
      },
      isClone: {
        type: Boolean,
        default: false,
      },
      config: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        contentType: null,
        editedItem: null,
        showSelectCustomer: false,
      }
    },
    computed: {        
      ...mapState('app', ['locale']),
      languages () {
        return this.$store.state.app.languages
      },
      isNew () {
        return this.editedID === 'new'
      },
      isDevelopment() {
        return process.env.VUE_APP_ENV === 'development'
      },
    },
    watch: {
      editedID (v) {
        if (v) this.handleGetItem()
      },
      newSelectedContentType (v) {
        if (v) this.handleGetItem()
      },
    },
    mounted () {
      if (this.editedID) this.handleGetItem()
    },
    methods: {
      getContentTypeData (v) {
        const aux = this.contentTypes.filter(x => x.id === v)
        return aux ? aux[0] : null
      },
      async handleGetItem () {
        if (this.isNew && !this.isClone) {
          if (this.newSelectedContentType) {
            this.contentType = this.getContentTypeData(this.newSelectedContentType)
            const aux = this.contentType.defaultData ? this.contentType.defaultData : {}
            aux.ID = 'new'
            aux.MenuID = this.newParentID
            aux.Type = this.contentType.id
            this.editedItem = aux
          }
          return
        }
        let extras = null
        const data = await new Promise((resolve) => api.getItem ('dtouch', 'v1/private/contents/', this.editedID)
          .then((response) => {
            if (response) {
              this.contentType = this.getContentTypeData(response.Type)
              if (response.Availability) {
                response.Availability.DayOfWeek = JSON.parse(response.Availability.DayOfWeek)
                response.Availability.DayOfMonth = JSON.parse(response.Availability.DayOfMonth)
                response.extras = extras
              }
              resolve(response)
            }
          }))
        // food extras clone
        if(this.isClone && data.Type === 27) {
          data.extras = await new Promise ((resolve) => apiDishOrders.getExtras(this.editedID) 
            .then((response) => {
              resolve(response)
            }))
        }
        // fod extras clone
        this.editedItem = data
      },
      handleChangeIsModified (v) {
        this.editedItem = v
        this.updateState()
      },
      updateState () {
        this.$store.state.app.dtouchContentEdit = {
          ...this.editedItem,
          LMD: new Date().getTime()
        }
      },
      handleChangeAvailability (v) {
        this.editedItem.Availability = v
      },
      handleChangeContentPadding (v) {
        this.editedItem.Padding = v
        this.updateState()
      },
      handleSave () {
        if (this.isNew || this.isClone)
          api.addItem ('dtouch', 'v1/private/contents/', this.editedItem)
            .then((response) => {
              // TODO
              if (response.data.insertBetween) this.onClose('fullRefresh', response.data.MenuID)
              else this.onClose('addContent', response.data)
            })
        else 
          api.updateItem ('dtouch', 'v1/private/contents/', this.editedItem.ID, this.editedItem)
          .then((response) => {
            const id = response.data && response.data.ID ? response.data.ID : null
            this.onClose('updateContent', id)
          })
      },
      handleDelete () {
        api.deleteItem('dtouch', `v1/private/contents/`, this.editedID)
          .then(response => {
            if(response) this.onClose('deleteContent', this.editedID)
          })
      },
      handleChangeInsertBetween (v) {
        this.editedItem.insertBetween = v
      },
    }
  }
</script>
<style>
.content-form {
}
.content-form .title {
}
.content-form .title-icon {
  margin-right:10px;
}
.content-form .alias {
  padding: 10px 0;
}
.content-form .action-buttons {
  margin-top: 15px;
}
.content-form .availability {
  background-color: #f3f0f0;
  border-radius: 5px;
  padding: 0 20px 20px 20px;
  margin-top: 15px;
}
.content-form .common-forms {
  margin-top: 15px;
}
</style>

